import { Box, Center, chakra, Flex, HStack, Image, Stack, VStack } from '@chakra-ui/react';
import React from 'react';
import GetAQoute from './Buttons/GetQoute';
import Whattsapp from './Buttons/Whattsapp';

const Package = ({ packages }) => {
  //   console.log(packages);
  //   packages?.map(pkg => console.log(pkg?.fields?.icon.fields?.file.url));

  return (
    <Flex
      bg="blue.50"
      p={10}
      w="full"
      justifyContent="center"
      alignItems="center"
    >
      <Box w="full" pt={8}>
        <Flex
          direction={{
            base: 'column',
            md: 'row',
          }}
          justifyContent="center"
          mb={{
            base: 6,
            sm: 0,
          }}
        >
          {packages?.map((pkg, i) => (
            <Flex
              key={i}
              flex={{
                base: 1,
                lg: 'initial',
              }}
              w={{
                lg: 2.3 / 7,
              }}
              rounded="lg"
              borderTopRightRadius={0}
              borderBottomLeftRadius="lg"
              bg="white"
              my={6}
              p={8}
              direction="column"
            >
              <VStack
                spacing={1}
                justifyContent="center"
                p={8}
                textAlign="center"
                w="full"
                shadow="xl"
                bgGradient='linear(to-l,  #fc77ba, #ae61fa)'
                rounded="10px"
              >
                <Image
                  src={pkg?.fields?.icon?.fields?.file?.url}
                  alt={pkg?.fields?.icon?.fields?.title}
                  boxSize={32}
                />
                <chakra.span fontSize="3xl" fontWeight="bold" color="white">
                  {pkg?.fields?.title}
                </chakra.span>
                <HStack spacing={3}>
                  <chakra.span
                    fontWeight="bold"
                    fontSize="6xl"
                    textShadow="2px 0 currentcolor"
                  >
                    <GetAQoute text="GET A QOUTE" />
                    {/* {pkg.fields.price} */}
                  </chakra.span>
                 
                </HStack>
              </VStack>
              <VStack
                fontSize="sm"
                spacing={8}
                h="full"
                bg="blue.50"
                borderBottomLeftRadius="lg"
                p={12}
              >
                <VStack
                  spacing={4}
                  w="full"
                  direction="column"
                  alignItems="start"
                >
                  {pkg?.fields?.included?.map(entry => (
                    <Flex alignSelf="start" w="full">
                      <chakra.p fontSize="lg" color="gray.900" />
                      {entry}
                    </Flex>
                  ))}
                </VStack>
                <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'column' }}
          >
            <Center>
              <GetAQoute text="GET A QOUTE" />
            </Center>
            <Whattsapp text="FREE CONSULTATION" />
          </Stack>
              </VStack>
            </Flex>
          ))}

          {/* <Flex
              flex={{
                base: 1,
                lg: "initial",
              }}
              w={{
                lg: 2.4 / 7,
              }}
              rounded="lg"
              bg="white"
                            mt={{
                base: 4,
                sm: -4,
              }}
              shadow="xl"
              zIndex={30}
              direction="column"
            >
              <VStack
                spacing={1}
                justifyContent="center"
                p={8}
                textAlign="center"
                w="full"
                shadow="xl"
              >
                <chakra.span fontSize="3xl" fontWeight="bold">
                  Growth
                </chakra.span>
                <HStack spacing={3}>
                  <chakra.span
                    fontWeight="bold"
                    fontSize="6xl"
                    textShadow="2px 0 currentcolor"
                  >
                    $149
                  </chakra.span>
                  <chakra.span
                    alignSelf="center"
                    fontSize="3xl"
                    _light={{
                      color: "gray.400",
                    }}
                  >
                    /month
                  </chakra.span>
                </HStack>
              </VStack>
              <VStack
                fontSize="sm"
                h="full"
                roundedBottom="lg"
                spacing={8}
                bg="gray.100"
                _dark={{
                  bg: "gray.800",
                }}
                p={12}
              >
                <VStack
                  spacing={4}
                  w="full"
                  direction="column"
                  alignItems="start"
                >
                  <Feature>Suppression Management</Feature>
                  <Feature>Email Tracking and Analytics </Feature>
                  <Feature>99.99% Guaranteed Uptime SLA</Feature>
                  <Feature>1,000 Email Address Validations</Feature>
                  <Feature>Limited 24/7 Ticket Support</Feature>
                </VStack>
                <Box display="inline-flex" rounded="md" shadow="md" w="full">
                  <chakra.a
                    w="full"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    px={5}
                    py={3}
                    border="solid transparent"
                    fontWeight="bold"
                    rounded="md"
                    _light={{
                      color: "white",
                    }}
                    bg="brand.600"
                    _dark={{
                      bg: "brand.500",
                    }}
                    _hover={{
                      bg: "brand.700",
                      _dark: {
                        bg: "brand.600",
                      },
                    }}
                    cursor="pointer"
                  >
                    Get started
                  </chakra.a>
                </Box>
              </VStack>
            </Flex>
  
            <Flex
              flex={{
                sm: 1,
                lg: "initial",
              }}
              w={{
                lg: 2.3 / 7,
              }}
              roundedTop="lg"
              borderBottomRightRadius="lg"
              borderTopLeftRadius={0}
              bg="white"
              _dark={{
                bg: "gray.700",
              }}
              my={6}
              direction="column"
            >
              <VStack
                spacing={1}
                justifyContent="center"
                p={8}
                textAlign="center"
                w="full"
                shadow="xl"
              >
                <chakra.span fontSize="3xl" fontWeight="bold">
                  Scale
                </chakra.span>
                <HStack spacing={3}>
                  <chakra.span
                    fontWeight="bold"
                    fontSize="6xl"
                    textShadow="2px 0 currentcolor"
                  >
                    $349
                  </chakra.span>
                  <chakra.span
                    alignSelf="center"
                    fontSize="3xl"
                    _light={{
                      color: "gray.400",
                    }}
                  >
                    /month
                  </chakra.span>
                </HStack>
              </VStack>
              <VStack
                fontSize="sm"
                spacing={8}
                h="full"
                bg="gray.100"
                _dark={{
                  bg: "gray.800",
                }}
                borderBottomRightRadius="lg"
                p={12}
              >
                <VStack
                  spacing={4}
                  w="full"
                  direction="column"
                  alignItems="start"
                >
                  <Feature>Suppression Management</Feature>
                  <Feature>Email Tracking and Analytics </Feature>
                  <Feature>99.99% Guaranteed Uptime SLA</Feature>
                </VStack>
                <Box
                  w="full"
                  ml={3}
                  display="inline-flex"
                  rounded="md"
                  shadow="md"
                >
                  <chakra.a
                    w="full"
                    display="inline-flex"
                    alignItems="center"
                    justifyContent="center"
                    px={5}
                    py={3}
                    border="solid transparent"
                    fontWeight="bold"
                    rounded="md"
                    color="brand.600"
                    _dark={{
                      color: "gray.100",
                    }}
                    bg="white"
                    _hover={{
                      bg: "brand.50",
                      _dark: {
                        bg: "gray.700",
                      },
                    }}
                    cursor="pointer"
                  >
                    Start your trial
                  </chakra.a>
                </Box>
              </VStack>
            </Flex>  */}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Package;
