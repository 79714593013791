import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOne } from '../../../clinet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import moment from 'moment/moment';
import { Center } from '@chakra-ui/layout';
import Benifits from '../../../components/Benifits/Benifits';
import Loading from '../../../components/Loading';
import SeoAndOgTag from '../../../components/SeoAndOgTag';
import Socials2 from '../../../components/Socials2';
import { usePWAInstall } from 'react-use-pwa-install';
import { Button } from '@chakra-ui/button';

const News = () => {
  const { slug } = useParams();

  const [news, setNews] = useState({});
  //   console.log(news);
  const install = usePWAInstall();

  useEffect(() => {
    if (install) {
      install();
    }
  }, [install]);

  useEffect(() => {
    fetchOne('news', slug)
      .then(res => {
        // console.log(res[0].fields);
        setNews(res[0].fields);
      })
      .catch(err => {
        console.log(err);
      });
  }, [slug]);
  // console.log(news, news?.keywords.map(kw => kw ));
  if (!news) return <Loading />;
  return (
    <div>
      <SeoAndOgTag
        metaTitle={news?.title}
        metaDescription={news?.ogDescription}
        metaImage={news?.mainImage?.fields?.file?.url}
        url={`/news/${slug}`}
        keywords={news?.keywords ? `${news?.keywords.map(kw => kw)},` : ''}
      />
      <div className="text-center pt-16 md:pt-32">
        <p className="text-sm md:text-base text-green-500 font-bold">
          {moment(news?.date).utc().format('DD-MM-YYYY')}
        </p>
        <h1 className="font-bold break-normal text-3xl md:text-5xl">
          {news?.title}
        </h1>
      </div>
      <br />
      <Center>
        <img
          src={news?.mainImage?.fields?.file?.url}
          alt={news?.title}
          className="container w-full max-w-lg mx-auto   bg-white bg-cover mt-1 rounded "
        />
      </Center>
      <br />
      <Socials2 />
      <br />
      <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add To Home Screen For More News
          </Button>
        )}
      </Center>
      <div className="container max-w-5xl mx-auto ">
        <div className="mx-0 sm:mx-6">
          <div
            className="bg-white w-full p-8 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal"
            styles="font-family:Georgia,serif;"
          >
            {/* <HStack spacing={3}>
              <Avatar
                size="md"
                name={blog?.auther}
                src={blog?.autherImage?.fields?.file?.url}
              />
              <Flex direction="column">
                <Text fontWeight="bold" fontSize="md">
                  {news?.auther}
                </Text>
                <Text fontWeight="light" fontSize="xs"></Text>
              </Flex>
            </HStack> */}
            <p className="text-2xl md:text-3xl mb-5">
              {documentToReactComponents(news?.description)}
            </p>
          </div>
        </div>
      </div>
      <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add To Home Screen For More News
          </Button>
        )}
      </Center>
      <div className="container px-6 py-16 mx-auto">
        {news.entries ? (
          <>
            {news?.entries?.map((entry, i) => (
              <Benifits
                key={i}
                title={entry?.fields?.title}
                description={entry?.fields?.description}
                imageUrl={entry?.fields?.image?.fields?.file?.url}
                imageTitle={entry?.fields?.image?.fields?.title}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
      <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add To Home Screen For More News
          </Button>
        )}
      </Center>
      <br/>
    </div>
  );
};

export default News;
