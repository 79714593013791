import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOne } from '../../../clinet';
import { useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Benifits from '../../../components/Benifits/Benifits';
import { Button, Center } from '@chakra-ui/react';
import Loading from '../../../components/Loading';
import SeoAndOgTag from '../../../components/SeoAndOgTag';
import Socials2 from '../../../components/Socials2';
import { usePWAInstall } from 'react-use-pwa-install';

const CaseStudy = () => {
  const { slug } = useParams();

  const [caseStudy, setCaseStudy] = useState({});
  // console.log(caseStudy);
  const install = usePWAInstall();

  useEffect(() => {
    if (install) {
      install();
    }
  }, [install]);

  useEffect(() => {
    fetchOne('caseStudies', slug)
      .then(res => {
        // console.log(res[0].fields);
        setCaseStudy(res[0].fields);
      })
      .catch(err => {
        console.log(err);
      });
  }, [slug]);
  // console.log(caseStudy);
  if (!caseStudy) return <Loading />;
  return (
    <div className="container px-2 mx-auto ">
      <SeoAndOgTag
        metaTitle={caseStudy?.title}
        metaDescription={caseStudy?.ogDescription}
        metaImage={caseStudy?.mainImage?.fields?.file?.url}
        url={`/casestudy/${slug}`}
        keywords={
          caseStudy?.keywords ? `${caseStudy?.keywords.map(kw => kw)}` : ''
        }
      />
      <div className=" text-center pt-10 md:pt-10 ">
        <h1 className="font-bold break-normal text-3xl md:text-5xl">
          {caseStudy?.title}
        </h1>
      </div>
      <Center>
        <img
          src={caseStudy?.mainImage?.fields?.file?.url}
          alt={caseStudy?.title}
          className="container w-full max-w-lg mx-auto   bg-white bg-cover mt-4 mb-4 rounded "
        />
      </Center>
      <Socials2 />
      <br />
      <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add To Home Screen For More Case Studies
          </Button>
        )}
      </Center>
      <div className=" max-w-5xl mx-auto py-1.5 ">
        <div className="mx-0 sm:mx-6">
          <div
            className="bg-white w-full p-5 md:p-24 text-xl md:text-2xl text-gray-800 leading-normal"
            styles="font-family:Georgia,serif;"
          >
            {/* <HStack spacing={3}>
              <Avatar
                size="md"
                name={blog?.auther}
                src={blog?.autherImage?.fields?.file?.url}
              />
              <Flex direction="column">
                <Text fontWeight="bold" fontSize="md">
                  {blog?.auther}
                </Text>
                <Text fontWeight="light" fontSize="xs"></Text>
              </Flex>
            </HStack> */}
            <p className="text-2xl md:text-3xl mb-5">
              {documentToReactComponents(caseStudy?.description)}
            </p>
          </div>
        </div>
      </div>
      <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add To Home Screen For More Case Studies
          </Button>
        )}
      </Center>
      <div className=" px-6 py-16 mx-auto">
        {caseStudy?.entries?.map((entry, i) => (
          <Benifits
            key={i}
            title={entry?.fields?.title}
            description={entry?.fields?.description}
            imageUrl={entry?.fields?.image?.fields?.file?.url}
            imageTitle={entry?.fields?.image?.fields?.title}
          />
        ))}
      </div>
      <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add To Home Screen For More Case Studies
          </Button>
        )}
      </Center>
      <br/>
    </div>
  );
};

export default CaseStudy;
