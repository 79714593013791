import { useState } from 'react';
import {
  Container,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Button,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
// Here we have used react-icons package for the icons
// import { GoLocation } from 'react-icons/go';
// import { BsPhone } from 'react-icons/bs';
// import { HiOutlineMail } from 'react-icons/hi';
import { Formik, Field } from 'formik';
import { CreatableSelect } from 'chakra-react-select';
import { components } from './Component';
import useFetchServices from '../../Navbar/getServices';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
// import axios from 'axios';
import { useNavigate } from 'react-router';
import emailjs from '@emailjs/browser';
const Contact = () => {
  const navigate = useNavigate();
  const [servicesValues, setServicesValues] = useState();
  // const [formSubmitted, setFormSubmitted] = useState(false);
  const [serviceList] = useFetchServices('industries');
  const servicesList = [];
  const [phone, setPhone] = useState('');

  const [whattsapp, setWhattsapp] = useState('');

  serviceList?.map(node => {
    // console.log(node.fields.industaryName);
    const s = node?.fields?.services;
    {
      // console.log('footer', n.fields.menuTitle);
      const obj = {
        label: node?.fields?.industaryName,
        options: [],
      };
      s.map(n => {
        const subObj = {
          value: n?.fields?.menuTitle,
          label: n?.fields?.menuTitle,
        };
        return obj.options.push(subObj);
      });
      servicesList.push(obj);
      // console.log(obj);
    }
    return node;
  });
  // console.log(servicesList);

  return (
    <Container maxW="100%" my={10} mx={{ base: 5, md: 8 }}>
      <Stack spacing={10}>
        <VStack
          spacing={8}
          w="100%"
          bg={useColorModeValue('white', 'gray.700')}
          rounded="lg"
          boxShadow="lg"
          p={{ base: 5, sm: 10 }}
        >
          <Formik
            initialValues={{
              from_name: '',
              user_email: '',
              user_phone: '',
              user_whattsapp: '',
              message: '',
            }}
            onSubmit={async (values, actions) => {
              // console.log(values, servicesValues, phone, whattsapp);
              const sL = [];
              servicesValues.map(s => sL.push(s.label));
              var data = {
                from_name: values.from_name,
                user_email: values.user_email,
                user_phone: phone,
                user_whattsapp: whattsapp,
                services: sL,
                message: values.message,
              };
              console.log(data);
              emailjs
                .send(
                  'service_h1bczt3',
                  'template_hmdjgfv',
                  data,
                  'uWFCHzoqN8A4ZQWHz'
                )
                .then(
                  result => {
                    console.log(result.text, 'FORM SUBMITTED');
                  },
                  error => {
                    console.log(error.text, 'FORM ERROR');
                  }
                );

              navigate('/thankyou');
              // try {

              //   const request = await axios
              //     .get(
              //       'https://sheet.best/api/sheets/9d4f4277-b8d2-4073-99b9-22cd23757648',
              //       data
              //     )
              //     .then(res => {
              //       console.log(res);
              //       return res;
              //     }).catch(error=>{
              //       console.log(error);
              //     })
              //     console.log(request);
              // } catch (error) {

              // }
              // setFormSubmitted(true)
              //   actions.setSubmitting(false);
              // actions.resetForm({
              //   values: {
              //     // the type of `values` inferred to be Blog
              //     name: '',
              //     email: '',
              //     message: '',
              //   },
              //   // you can also set the other form states here
              // });
              // setServicesValues()
            }}
          >
            {/* {!formSubmitted ? <>your form</> : <Text>Thank you for submitting your form</Text>} */}
            {({ handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <VStack spacing={3} w="100%">
                  <Stack w="100%" spacing={3} direction="column">
                    <FormControl isRequired>
                      <FormLabel htmlFor="name" id="name">
                        Full Name
                      </FormLabel>
                      <Field
                        as={Input}
                        type="text"
                        name="from_name"
                        placeholder="Jhon Doe"
                        rounded="md"
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Field
                        as={Input}
                        type="email"
                        name="user_email"
                        placeholder="test@test.com"
                        rounded="md"
                      />
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Phone Number</FormLabel>
                      <Field
                        vlaue={phone}
                        width="full"
                        // isMulti
                      >
                        {({ field, form }) => (
                          <PhoneInput
                            name="user_phone"
                            type="phone"
                            country={'us'} //eg
                            enableSearch={true}
                            countryCodeEditable
                            {...field}
                            value={phone}
                            onChange={phone => setPhone(phone)}
                          />
                        )}
                      </Field>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Whattsapp</FormLabel>
                      <Field
                        type="whattsapp"
                        isRequired
                        // isMulti
                        render={() => (
                          <PhoneInput
                            name="user_whattsapp"
                            country={'us'} //eg
                            enableSearch={true}
                            // countryCodeEditable={true}
                            isRequired={true}
                            value={whattsapp}
                            onChange={whattsapp => setWhattsapp(whattsapp)}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <FormControl isRequired>
                    <FormLabel>
                      Select the Services "(You can ask for custom service too
                      by just typing)"{' '}
                    </FormLabel>
                    <Field
                      type="select"
                      // isMulti
                      render={() => (
                        <CreatableSelect
                          // isRequired="true"
                          name="services"
                          isMulti
                          onChange={option => {
                            setServicesValues(option);
                            // console.log(option);
                          }}
                          id="services"
                          options={servicesList}
                          placeholder="Select Services you want..."
                          components={components}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl id="message">
                    <FormLabel htmlFor="message">Message</FormLabel>
                    <Field
                      as={Textarea}
                      name="message"
                      size="lg"
                      placeholder="Enter your message"
                      rounded="md"
                    />
                  </FormControl>
                </VStack>
                <VStack w="100%">
                  <Button
                    my="2"
                    type="submit"
                    disabled={Formik.isSubmitting}
                    bgGradient="linear(to-l, #ae61fa, #fc77ba)"
                    _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
                    color="white"
                    rounded="md"
                    w={{ base: '100%', md: 'max-content' }}
                  >
                    Send Message
                  </Button>
                </VStack>
              </form>
            )}
          </Formik>
        </VStack>
      </Stack>
    </Container>
  );
};

export default Contact;
