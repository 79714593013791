import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchData } from '../../clinet';
import Hero from '../../components/Hero/Hero';
import Loading from '../../components/Loading';
import Benifits from '../../components/Benifits/Benifits';
import { Box, Center } from '@chakra-ui/layout';
import SeoAndOgTag from '../../components/SeoAndOgTag';
import { usePWAInstall } from 'react-use-pwa-install';
import { Button } from '@chakra-ui/button';

// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
const Home = () => {
  const [homeData, setHomeData] = useState([]);

  const [loading, setLoading] = useState();
  const install = usePWAInstall();

  useEffect(() => {
    if (install) {
      install();
    }
  }, [install]);

  useEffect(() => {
    fetchData('homePage')
      .then(res => {
        // console.log(res[0].fields);
        setHomeData(res[0].fields);
      })
      .finally(() => setLoading(false))
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // console.log(homeData);
  // homeData?.benifets.map(node => console.log(node.fields.image.fields.title));
  if (loading) return <Loading />;
  return (
    <Box bg="blue.50">
      <SeoAndOgTag
    metaTitle={homeData?.title}
    metaDescription={homeData?.ogDescription}
    metaImage={homeData?.mainImage?.fields?.file?.url}
    url={`/`}
    keywords={homeData?.keywords ? `${homeData?.keywords.map(kw => kw  )}` : ""}
  />
<Hero title={homeData?.title}
        description={homeData?.description}
        imageUrl={homeData?.mainImage?.fields?.file?.url}
        imageTitle={homeData?.mainImage?.fields?.title}/>
        <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add ULTRASTACK To Home Screen
          </Button>
        )}
      </Center>
        <div className="container px-6 py-16 mx-auto">
        {homeData?.benifets &&
          (homeData?.benifets).map((benifit, i) => (
            <Benifits
              key={i}
              title={benifit?.fields?.title}
              description={benifit?.fields?.description}
              imageUrl={benifit?.fields?.image?.fields?.file?.url}
              imageTitle={benifit?.fields?.image?.fields?.title}
            />
          ))}
      </div>
      <Center>
        {install && (
          <Button
            onClick={install}
            colorScheme="blue"
            size="md"
            bgGradient="linear(to-l, #ae61fa, #fc77ba)"
            _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
          >
            Add ULTRASTACK To Home Screen
          </Button>
        )}
      </Center>
    </Box>
  );
};

export default Home;
