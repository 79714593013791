import { useState, useEffect } from "react";
import { fetchData } from "../../clinet";

const useFetchServices = (props) => {
    const [data, setData] = useState([]);
    
    // console.log(props);
    useEffect(() => {
        fetchData(props)
          .then(res => {
            // console.log(res);
            setData(res[0].fields);
          })
          .catch(err => {
            console.log(err);
          });
      }, [props]);

  return [data];
};

export default useFetchServices;