import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchOne } from '../../../clinet';
import Benifits from '../../../components/Benifits/Benifits';
import Hero from '../../../components/Hero/Hero';
import { Box, Center, SimpleGrid } from '@chakra-ui/react';
import Faq from '../../../components/Faq';
import Feature from '../../../components/Features/Features';
import Package from '../../../components/Package';
import Loading from '../../../components/Loading';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
// import { useDisclosure } from '@chakra-ui/hooks';
import {
  AspectRatio,
  Container,
  Flex,
  HStack,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Icon } from '@chakra-ui/icon';
import { AiOutlineStar } from 'react-icons/ai';
import { Image } from '@chakra-ui/image';
import { Tooltip } from '@chakra-ui/tooltip';
import SeoAndOgTag from '../../../components/SeoAndOgTag';
import Socials2 from '../../../components/Socials2';

const LazyImage = props => {
  const { src, title } = props;
  return <Image src={src} objectFit="cover" alt={title} />;
};

const Service = () => {
  const { slug } = useParams();
  const [singleService, setSingleService] = useState({});
  // const { isOpen, onOpen, onClose } = useDisclosure();

  // const handleClick = () => {
  //   onOpen();
  // };

  // const handleLinkClick = (e, link) => {
  //   window.open(link);
  //   e.stopPropagation();
  // };

  // console.log(singleService);

  // useEffect(() => {
  //   serviceList.map(node => {
  //     //   console.log(node.fields);
  //     const s = node.fields.services;
  //     {
  //       s.map(n => {
  //         // console.log(n.fields);
  //         if (n.fields.titleSlug == slug) {
  //           console.log(n.fields);
  //           setSingleService(n.fields)
  //           return n
  //         }
  //         return slug;
  //       });
  //     }
  //     // console.log(s);
  //     return node;
  //   });
  // }, [slug, serviceList]);

  useEffect(() => {
    fetchOne('Services', slug)
      .then(res => {
        // console.log(res[0].fields);
        setSingleService(res[0].fields);
      })
      .catch(err => {
        console.log(err);
      });
  }, [slug]);
  console.log(singleService);
  if (!singleService) return <Loading />;
  return (
    <>
      <SeoAndOgTag
        metaTitle={singleService?.title}
        metaDescription={singleService?.ogDescription}
        metaImage={singleService?.mainImage?.fields?.file?.url}
        url={`/service/${slug}`}
        keywords={
          singleService?.keywords
            ? `${singleService?.keywords.map(kw => kw)}`
            : ''
        }
      />
      <Box bg="blue.50">
        <Hero
          title={singleService?.title}
          description={singleService?.description}
          imageUrl={singleService?.mainImage?.fields?.file?.url}
          imageTitle={singleService?.mainImage?.fields?.title}
        />
        <Socials2 />
        <br />
        <Center>
          <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
            HOW IT WORKS
          </p>
        </Center>
        <div className="container px-6 py-16 mx-auto">
          {singleService?.benifets &&
            (singleService?.benifets).map((benifit, i) => (
              <Benifits
                key={i}
                title={benifit?.fields?.title}
                description={benifit?.fields?.description}
                imageUrl={benifit?.fields?.image?.fields?.file?.url}
                imageTitle={benifit?.fields?.image?.fields?.title}
              />
            ))}
        </div>
        <Socials2 />
        <br />
        <Center>
          <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
            WHAT YOU WILL GET
          </p>
        </Center>

        <SimpleGrid columns={[1, 2, 3]} spacing="10px" m={'10'}>
          {singleService?.features?.map((feature, i) => (
            <span key={i}>
              {/* <p>{feature.fields.image.fields.title}</p> */}

              <Feature
                // data = {feature?.fields}
                title={feature?.fields?.title}
                description={feature?.fields?.description}
                imageUrl={feature?.fields?.image?.fields?.file?.url}
                imageTitle={feature?.fields?.image?.fields?.title}
              />
            </span>
          ))}
        </SimpleGrid>
        <Socials2 />
        <br />
        <Box bg="white">
          {singleService?.isPackage ? (
            <Box bg="white">
              <Package packages={singleService?.packages} />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Center>
      <p className='mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl'>
        FAQ's
      </p>
      </Center>
        {singleService?.faqs?.map((faq, i) => (
          <Center>
            {' '}
            <Faq key={i} q={faq?.fields?.question} a={faq?.fields?.answer} />
          </Center>
        ))}

        <div className="bg-blue-50">
          <Container maxW="7xl" p="5" mx="auto">
            <Center>
              <Heading>
                {' '}
                <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
                  Portfolio
                </p>
              </Heading>
            </Center>
            <SimpleGrid columns={[1, 2]} spacing={4} mt={8}>
              {singleService?.portfolio?.map((project, i) => (
                <>
                  {project?.fields?.isVideo ? (
                    <Box 
                    // onClick={handleClick} 
                    cursor="pointer" size="xl">
                      <VStack
                        rounded="xl"
                        borderWidth="1px"
                        bg={'gray.100'}
                        borderColor={'gray.700'}
                        _hover={{
                          shadow: 'lg',
                          textDecoration: 'none',
                        }}
                        overflow="hidden"
                        align="start"
                        spacing={0}
                      >
                        <Box position="relative" w="100%">
                          <AspectRatio
                            ratio={1.85 / 1}
                            w="100%"
                            borderBottomWidth="1px"
                            borderColor={'gray.100'}
                          >
                            <iframe
                              src={project?.fields?.image?.fields?.file?.url}
                              title={project?.fields?.image?.fields?.title}
                              allowFullScreen
                              allowPinchZoom
                            />
                          </AspectRatio>
                        </Box>

                        <VStack
                          py={2}
                          px={[2, 4]}
                          spacing={1}
                          align="start"
                          w="100%"
                        >
                          <Flex justifyContent="space-between" width="100%">
                            <Tooltip
                              hasArrow
                              label={project?.fields?.title}
                              placement="top"
                            >
                              <HStack>
                                <Text
                                  fontSize="sm"
                                  noOfLines={1}
                                  fontWeight="600"
                                  align="left"
                                  // onClick={e =>
                                  //   handleLinkClick(
                                  //     e,
                                  //     project?.fields?.image?.fields?.file?.url
                                  //   )
                                  // }
                                >
                                  {project?.fields?.title}
                                </Text>
                              </HStack>
                            </Tooltip>
                            <Flex alignItems="center">
                              <Icon
                                as={AiOutlineStar}
                                boxSize="0.9em"
                                mt="1px"
                              />
                              {/* <Box as="span" ml="1" fontSize="sm">
                    stargazers_count
                  </Box> */}
                            </Flex>
                          </Flex>
                        </VStack>
                      </VStack>
                    </Box>
                  ) : (
                    <Box 
                    // onClick={handleClick} 
                    cursor="pointer" size="xl">
                      <VStack
                        rounded="xl"
                        borderWidth="1px"
                        bg={'gray.100'}
                        borderColor={'gray.700'}
                        _hover={{
                          shadow: 'lg',
                          textDecoration: 'none',
                        }}
                        overflow="hidden"
                        align="start"
                        spacing={0}
                      >
                        <Box position="relative" w="100%">
                          <AspectRatio
                            ratio={1.85 / 1}
                            w="100%"
                            borderBottomWidth="1px"
                            borderColor={'gray.100'}
                          >
                            <a
                        href={project?.fields?.image?.fields?.file?.url}
                        title={project?.fields?.image?.fields?.title}
                        target="_blank"
                        rel="noreferrer"
                      >
                          <LazyImage
                            src={project?.fields?.image?.fields?.file?.url}
                            title={project?.fields?.image?.fields?.title}
                          />
                      </a>
                          </AspectRatio>
                        </Box>

                        <VStack
                          py={2}
                          px={[2, 4]}
                          spacing={1}
                          align="start"
                          w="100%"
                        >
                          <Flex justifyContent="space-between" width="100%">
                            <Tooltip
                              hasArrow
                              label={project?.fields?.title}
                              placement="top"
                            >
                              <HStack>
                                <Text
                                  fontSize="sm"
                                  noOfLines={1}
                                  fontWeight="600"
                                  align="left"
                                  // onClick={e =>
                                  //   handleLinkClick(
                                  //     e,
                                  //     project?.fields?.image?.fields?.file?.url
                                  //   )
                                  // }
                                >
                                  {project?.fields?.title}
                                </Text>
                              </HStack>
                            </Tooltip>
                            <Flex alignItems="center">
                              <Icon
                                as={AiOutlineStar}
                                boxSize="0.9em"
                                mt="1px"
                              />
                              {/* <Box as="span" ml="1" fontSize="sm">
                        stargazers_count
                      </Box> */}
                            </Flex>
                          </Flex>
                        </VStack>
                      </VStack>
                      <Modal
                        // isOpen={isOpen}
                        // onClose={onClose}
                        isCentered
                        allowPinchZoom
                      >
                        <ModalOverlay />
                        <ModalContent bg="none" maxW="28rem" w="auto">
                          <ModalBody
                            p={0}
                            rounded="lg"
                            overflow="hidden"
                            bg="none"
                          >
                            <Center>
                              <Image
                                src={project?.fields?.image?.fields?.file?.url}
                                rounded="lg"
                                alt="Repo image"
                              />
                            </Center>
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </Box>
                  )}
                </>
              ))}
            </SimpleGrid>
          </Container>
        </div>

        <section className="bg-blue-50 ">
          <div className="container mx-auto px-6 py-10">
            <div className="text-center">
              <h1 className="text-3xl font-semibold capitalize text-gray-800  lg:text-4xl">
                <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
                  Blog
                </p>
              </h1>

              {/* <p className="mx-auto mt-4 max-w-lg text-gray-500">Salami mustard spice tea fridge authentic Chinese food dish salt tasty liquor. Sweet savory foodtruck pie.</p> */}
            </div>

            <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 xl:grid-cols-3">
              {singleService?.blogs?.map((blog, i) => (
                <div key={i} className="shadow-2xl rounded-2xl bg-white">
                  <NavLink key={i} to={`/blog/${blog?.fields?.titleSlug}`}>
                    <div className="relative ">
                      <img
                        className="h-64 w-full rounded-lg object-contain object-center lg:h-80"
                        src={blog?.fields?.heroImage?.fields?.file?.url}
                        alt=""
                      />

                      <div className="absolute bottom-0 flex bg-blue-100 p-3 ">
                        <img
                          className="h-10 w-10 rounded-full object-cover object-center"
                          src={blog?.fields?.autherImage?.fields?.file?.url}
                          alt={blog?.fields?.auther}
                        />

                        <div className="mx-4">
                          <h1 className="text-sm text-gray-700 ">
                            {blog?.fields?.auther}
                          </h1>
                          {/* <p className="text-sm text-gray-500 ">Creative Director</p> */}
                        </div>
                      </div>
                    </div>

                    <Center>
                      <h1 className="mt-6 text-xl font-semibold text-gray-800 ">
                        {blog?.fields?.title}
                      </h1>
                    </Center>
                    <Center>
                      <p>
                        {moment(blog?.fields?.date).utc().format('DD-MM-YYYY')}
                      </p>
                    </Center>
                    <Center>
                      <p className="mt-4 inline-block text-blue-500 underline hover:text-blue-400 ">
                        Read more
                      </p>
                    </Center>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Box>
    </>
  );
};

export default Service;
