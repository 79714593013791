import React from 'react';
import useFetchServices from '../../Navbar/getServices';
import { NavLink } from 'react-router-dom';
import { Center } from '@chakra-ui/react';
import moment from 'moment/moment';
import Loading from '../../../components/Loading';

const Blogs = () => {
  const [data] = useFetchServices('blog');
  // console.log(data);
  if (!data) return <Loading />;
  return (
    <div>
     
      <section className="bg-white ">
        <div className="container mx-auto px-6 py-10">
          <div className="text-center">
            <h1 className="text-3xl font-semibold capitalize text-gray-800  lg:text-4xl">
              Blogs
            </h1>

            {/* <p className="mx-auto mt-4 max-w-lg text-gray-500">Salami mustard spice tea fridge authentic Chinese food dish salt tasty liquor. Sweet savory foodtruck pie.</p> */}
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 xl:grid-cols-3">
            {data?.map((blog, i) => (
              <div key={i} className="shadow-2xl rounded-2xl">
                <NavLink key={i} to={`/blog/${blog?.fields?.titleSlug}`}>
                  <div className="relative ">
                    <img
                      className="h-64 w-full rounded-lg object-contain object-center lg:h-80"
                      src={blog?.fields?.heroImage?.fields?.file?.url}
                      alt=""
                    />

                    <div className="absolute bottom-0 flex bg-white p-3 ">
                <img className="h-10 w-10 rounded-full object-cover object-center" src={blog?.fields?.autherImage?.fields?.file?.url} alt={blog?.fields?.auther} />

                <div className="mx-4">
                <h1 className="text-sm text-gray-700 ">{blog?.fields?.auther}</h1>
                {/* <p className="text-sm text-gray-500 ">Creative Director</p> */}
                </div>
            </div>
                  </div>

                  <Center>
                    <h1 className="mt-6 text-xl font-semibold text-gray-800 ">
                      {blog?.fields?.title}
                    </h1>
                    
                    {/* <hr className="my-6 w-32 text-blue-500" />

<p className="text-sm text-gray-500 ">
{documentToReactComponents(study?.fields?.description)}
</p> */}
                  </Center>
                  <Center>

<p>{moment(blog?.fields?.date).utc().format('DD-MM-YYYY')}</p>
                  </Center>
                  <Center>
                    <p className="mt-4 inline-block text-blue-500 underline hover:text-blue-400 ">
                      Read more
                    </p>
                  </Center>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </section>

    </div>
  );
};

export default Blogs;
