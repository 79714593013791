import React from 'react'
import useFetchServices from '../GetData'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { Box,  Heading,  Text } from '@chakra-ui/react';
import Loading from '../../../components/Loading';


const TermsAndConditions = () => {
  const [data] = useFetchServices("termOfServices")
  // console.log(data);
if (!data) return <Loading />;
  return (
    <Box p={['10', '20']}>
       <Heading size='md'> Our Terms & Conditions</Heading>
    <Text>{documentToReactComponents(data?.termOfServices)}</Text>
    </Box>
  )
}

export default TermsAndConditions