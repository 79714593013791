import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

const Faq = ({ q, a }) => {
  return (
    <Box px={["0", "10", ]} bg="blue.50">
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="center">
                {q}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>{documentToReactComponents(a)}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default Faq;
