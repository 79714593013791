import React from "react";
import {  Icon } from "@chakra-ui/react";
import {  chakraComponents } from "chakra-react-select";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { AiFillCaretDown } from "react-icons/ai";

export const components = {
    ClearIndicator: (props) => (
      <chakraComponents.ClearIndicator {...props}>
        <Icon as={IoMdCloseCircleOutline} w={4} h={4} />
      </chakraComponents.ClearIndicator>
    ),
    DropdownIndicator: (props) => (
      <chakraComponents.DropdownIndicator {...props}>
        <Icon as={AiFillCaretDown} />
      </chakraComponents.DropdownIndicator>
    )
  };