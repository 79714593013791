import React, { useEffect, useState } from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import "./index.css"
// import { ColorModeSwitcher } from './ColorModeSwitcher';
import { fetchData } from './clinet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './container/Home/Home';
import PrivacyPolicy from './container/WhoWeAre/PrivacyPolicy';
import Blog from './container/WhoWeAre/Blog/Blog';
import Industry from './container/WhatWeDo/Indutry';
import Service from './container/WhatWeDo/Service';
import Package from './container/WhatWeDo/Package';
import Studies from './container/WhoWeAre/Studies';
// import Skills from "./container/Skills/Skills"
import Blogs from './container/WhoWeAre/Blog';
import Navbar from './container/Navbar/Navbar';
import Footer from './container/Footer/Footer';
import Portfolio from './container/WhoWeAre/Portfolio';
import TermsAndConditions from './container/WhoWeAre/TermsOfServices';
import RefundPolicy from './container/WhoWeAre/RefundPolicy';
import CaseStudy from './container/WhoWeAre/Studies/CaseStudy';
import AllNews from './container/WhoWeAre/News/Index';
import News from './container/WhoWeAre/News/News';
import Testimonial from './container/WhoWeAre/Testimonial/Testimonial';
import Contact from './container/WhoWeAre/Contact/Form';
import Service2 from './container/WhatWeDo/Service/Service2';
import Loading from './components/Loading';
import ThankYou from './container/Thankyou/index.jsx';
// import Contact from './components/ContactUs';
function App() {
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    fetchData('industries')
      .then
      // (res)=>console.log(res)
      ()
      .finally(() => setLoading(false))
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  if (loading) return <Loading />;
  return (
    <Router>
      <ChakraProvider theme={theme}>
        {/* <ColorModeSwitcher justifySelf="flex-end" /> */}
        <Navbar />
        
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/industry/:slug" element={<Industry />} />
          <Route
            path="/service/:slug"
            element={<Service />}
          />
          <Route
            path="/service/2/:slug"
            element={<Service2 />}
          />
          <Route path="/package/:slug" element={<Package />} />

          <Route path="/portfolio" element={<Portfolio />} />
          
          <Route path="/feedback" element={<Testimonial />} />
          <Route path="/caseStudies" element={<Studies />} />
          <Route path="/casestudy/:slug" element={<CaseStudy />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />

          <Route path="/blog" element={<Blogs />} />
          
          <Route path="/contact" element={<Contact />} />
          <Route path="/thankyou" element={<ThankYou />} />

          <Route path="/blog/:slug" element={<Blog />} />

          <Route path="/news" element={<AllNews />} />
          <Route path="/news/:slug" element={<News />} />

          <Route path="*" element={() => <h2>404 not found</h2>} />
        </Routes>
        {/* <Contact /> */}
        <Footer />
        
      </ChakraProvider>
    </Router>
  );
}

export default App;
