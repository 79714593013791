import { Center, Stack } from '@chakra-ui/layout';
import React from 'react';
import GetAQoute from './Buttons/GetQoute';
import Whattsapp from './Buttons/Whattsapp';

const Section = ({ title, description, imageUrl }) => {
  return (
    <div className="container mx-auto px-1 py-1 text-center justify-center">
      <div className="mx-auto max-w-full">
        <h1 className="text-3xl font-bold bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent uppercase lg:text-4xl">
          {title}
        </h1>
        <p className="mt-6 text-gray-500 ">{description}</p>
        {/* <button className="mt-6 rounded-lg bg-blue-600 px-6 py-2.5 text-center text-sm font-medium capitalize leading-5 text-white hover:bg-blue-500 focus:outline-none lg:mx-0 lg:w-auto">Get A Qoute</button> */}
        <Center>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}
            pt={'4'}
          >
            <Center>
              <GetAQoute text="GET A QOUTE" />
            </Center>
            <Whattsapp text="FREE CONSULTATION" />
          </Stack>
        </Center>
      </div>

      <div className="mt-10 flex justify-center max-w-full">
        <img
          className="h-96 w-full rounded-xl object-cover lg:w-4/5"
          src={imageUrl}
          alt={title}
        />
      </div>
    </div>
  );
};

export default Section;
