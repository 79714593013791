
import { Center } from '@chakra-ui/react';
import Socials2 from './Socials2';

export default function Socials() {
  return (
    <Center p={1}>

     <Socials2/>
      
    </Center>
  );
}
