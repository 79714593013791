import React from 'react';

import logo from '../../assets/logo.png';
import { Center } from '@chakra-ui/layout';
import { FaWhatsapp } from 'react-icons/fa';
const ThankYou = () => {
  return (
    <div className="overflow-y-auto sm:p-0  pr-4 pb-20 pl-4 bg-blue-100">
      <div className="flex justify-center items-center text-center min-h-screen sm:block">
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div className="inline-block text-left bg-white rounded-lg overflow-hidden align-bottom transition-all transform shadow-2xl sm:my-4 sm:align-middle sm:max-w-xl sm:w-full">
          <div className="items-center w-full mr-auto ml-auto relative max-w-7xl md:px-12 lg:px-24">
            ​
            <p className="mt-8 text-2xl font-semibold leading-none text-white tracking-tighter lg:text-3xl">
              {/* <Text fontWeight={'extrabold'} fontSize={'2xl'}> */}
              <p className="bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent hover:bg-gradient-to-l">
                <Center>THANK YOU</Center>
              </p>
              {/* </Text> */}
            </p>
            <div className="grid grid-cols-1">
              <div className="mt-4 mr-auto mb-4 ml-auto bg-white max-w-lg">
                <div className="flex flex-col items-center pt-6 pr-6 pb-6 pl-6">
                  <a href="/">
                    {' '}
                    <img
                      src={logo}
                      alt="ULTRASTACK"
                      className="flex-shrink-0 object-cover object-center btn- flex w-1/2 h-1/2 mr-auto -mb-8 ml-auto rounded-full shadow-xl"
                    />
                    <br />
                    <p className="mt-8 text-2xl font-semibold leading-none text-white tracking-tighter lg:text-3xl">
                      {/* <Text fontWeight={'extrabold'} fontSize={'2xl'}> */}
                      <p className="bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent hover:bg-gradient-to-l">
                        <Center>ULTRASTACK</Center>
                      </p>
                      {/* </Text> */}
                    </p>
                    <p className="mt-3 text-base leading-relaxed text-center text-gray-900">
                      Our Team Will Contact You Shortly
                    </p>
                  </a>
                  <div className="w-full mt-6">
                    <a
                      href="/"
                      className="flex text-center items-center justify-center w-full pt-4 pr-10 pb-4 pl-10 text-base
                    font-medium text-white bg-indigo-500 rounded-xl transition duration-500 ease-in-out transform
                    hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Go To Home Page
                    </a>
                  </div>
                  <br />
                  <a
                    href={'https://wa.me/+923138876876'}
                    target="_blank"
                    rel="noreferrer"
                    className="flex text-center items-center justify-center w-full pt-4 pr-10 pb-4 pl-10 text-base
                      font-medium text-white bg-green-500 rounded-xl transition duration-500 ease-in-out transform
                      hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <FaWhatsapp size={'26px'} /> Connect On Whattsapp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
