import React from 'react';
import useFetchServices from '../GetData';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Text } from '@chakra-ui/react';
import Loading from '../../../components/Loading';

const RefundPolicy = () => {
  const [data] = useFetchServices('refundPolicy');
  if (!data) return <Loading />;
  return (
    <Box p={['10', '20']}>
      <Text>{documentToReactComponents(data?.refundPolicy)}</Text>
    </Box>
  );
};

export default RefundPolicy;
