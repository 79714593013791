import React from 'react';
import useFetchServices from '../../Navbar/getServices';
import { Container, Heading, SimpleGrid } from '@chakra-ui/layout';
import {
  // useDisclosure,
  Box,
  Image,
  VStack,
  Text,
  AspectRatio,
  HStack,
  Tag,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Center,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineStar } from 'react-icons/ai';
import Loading from '../../../components/Loading';

const LazyImage = props => {
  const { src, title } = props;
  return <Image src={src} objectFit="cover" alt={title} />;
};
const Portfolio = () => {
  const [data] = useFetchServices('projects');
  // const { isOpen, onOpen, onClose } = useDisclosure();

  // const handleClick = () => {
  //   onOpen();
  // };

  // const handleLinkClick = (e, link) => {
  //   window.open(link);
  //   e.stopPropagation();
  // };

  // const Categories = ['All'];
  // var FilteredCategory = [];
  // const [works, setWorks] = useState([]);
  // const [activeFilter, setActiveFilter] = useState("All");

  // const [filterWork, setFilterWork] = useState([]);
  // console.log(data);

  // function getUnique(array) {
  //   var uniqueArray = [];

  //   // Loop through array values
  //   for (let i = 0; i < array.length; i++) {
  //     if (uniqueArray.indexOf(array[i]) === -1) {
  //       uniqueArray.push(array[i]);
  //     }
  //   }
  //   return uniqueArray;
  // }

  // data?.map((p) => {
  //   p?.fields?.categories?.map(c => {
  //     // console.log(c?.fields?.name);
  //     Categories.map(category => {
  //       // console.log(c?.fields?.name, category);

  //       Categories.push(c?.fields?.name);

  //       return category;
  //     });
  //     return p;
  //   });
  //   return p;
  // });
  // FilteredCategory = getUnique(Categories);
  // console.log(FilteredCategory);

  // const handleWorkFilter = item => {
  //   console.log(item);
  //   setActiveFilter(item);
  //   setTimeout(() => {

  //     if (item === "All") {
  //       setFilterWork(data);
  //     } else {
  //       setFilterWork(works.filter((work) => work.tags.includes(item)));
  //     }
  //   }, 500);
  // };

  if (!data) return <Loading />;
  return (
    <div className="bg-blue-50">
      <Container maxW="7xl" p="5" mx="auto">
        <Center>
          <Heading>
            {' '}
            <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
              Portfolio
            </p>
          </Heading>
        </Center>
        <SimpleGrid columns={[1, 2]} spacing={4} mt={8}>
          {data?.map((project, i) => (
            <>
              {project?.fields?.isVideo ? (
                <></>
              ) : (
                <Box
                  // onClick={handleClick}
                  cursor="pointer"
                  size="xl"
                >
                  <VStack
                    rounded="xl"
                    borderWidth="1px"
                    bg={'gray.100'}
                    borderColor={'gray.700'}
                    _hover={{
                      shadow: 'lg',
                      textDecoration: 'none',
                    }}
                    overflow="hidden"
                    align="start"
                    spacing={0}
                  >
                    <Box position="relative" w="100%">
                        <AspectRatio
                          ratio={1.85 / 1}
                          w="100%"
                          borderBottomWidth="1px"
                          borderColor={'gray.100'}
                        >
                      <a
                        href={project?.fields?.image?.fields?.file?.url}
                        title={project?.fields?.image?.fields?.title}
                        target="_blank"
                        rel="noreferrer"
                      >
                          <LazyImage
                            src={project?.fields?.image?.fields?.file?.url}
                            title={project?.fields?.image?.fields?.title}
                          />
                      </a>
                        </AspectRatio>
                    </Box>

                    <VStack
                      py={2}
                      px={[2, 4]}
                      spacing={1}
                      align="start"
                      w="100%"
                    >
                      <Flex justifyContent="space-between" width="100%">
                        <Tooltip
                          hasArrow
                          label={project?.fields?.title}
                          placement="top"
                        >
                          <HStack>
                            <Text
                              fontSize="sm"
                              noOfLines={1}
                              fontWeight="600"
                              align="left"
                              // onClick={e =>
                              //   handleLinkClick(
                              //     e,
                              //     project?.fields?.image?.fields?.file?.url
                              //   )
                              // }
                            >
                              {project?.fields?.title}
                            </Text>
                          </HStack>
                        </Tooltip>
                        <Flex alignItems="center">
                          <Icon as={AiOutlineStar} boxSize="0.9em" mt="1px" />
                          {/* <Box as="span" ml="1" fontSize="sm">
                        stargazers_count
                      </Box> */}
                        </Flex>
                      </Flex>
                      <Flex justifyContent="space-between" width="100%">
                        <Box>
                          <HStack spacing="1">
                            {project?.fields?.categories?.map((tech, j) => (
                              <Tag key={j} size="sm" colorScheme="cyan">
                                <Text
                                  fontSize={['0.55rem', 'inherit', 'inherit']}
                                >
                                  {tech?.fields?.name}
                                </Text>
                              </Tag>
                            ))}
                          </HStack>
                        </Box>
                      </Flex>
                    </VStack>
                  </VStack>
                  <Modal
                    // isOpen={isOpen}
                    // onClose={onClose}
                    isCentered
                    allowPinchZoom
                  >
                    <ModalOverlay />
                    <ModalContent bg="none" maxW="28rem" w="auto">
                      <ModalBody p={0} rounded="lg" overflow="hidden" bg="none">
                        <Center>
                          <Image
                            src={project?.fields?.image?.fields?.file?.url}
                            rounded="lg"
                            alt="Repo image"
                          />
                        </Center>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </Box>
              )}
            </>
          ))}
        </SimpleGrid>
        <br />
        <Center>
          <Heading>
            {' '}
            <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
              Video Portfolio
            </p>
          </Heading>
        </Center>
        <SimpleGrid columns={[1, 2]} spacing={4} mt={8}>
          {data?.map((project, i) => (
            <>
              {project?.fields?.isVideo ? (
                <Box
                  // onClick={handleClick}
                  cursor="pointer"
                  size="xl"
                >
                  <VStack
                    rounded="xl"
                    borderWidth="1px"
                    bg={'gray.100'}
                    borderColor={'gray.700'}
                    _hover={{
                      shadow: 'lg',
                      textDecoration: 'none',
                    }}
                    overflow="hidden"
                    align="start"
                    spacing={0}
                  >
                    <Box position="relative" w="100%">
                      <AspectRatio
                        ratio={1.85 / 1}
                        w="100%"
                        borderBottomWidth="1px"
                        borderColor={'gray.100'}
                      >
                        <iframe
                          src={project?.fields?.image?.fields?.file?.url}
                          title={project?.fields?.image?.fields?.title}
                          allowFullScreen
                          allowPinchZoom
                        />
                      </AspectRatio>
                    </Box>

                    <VStack
                      py={2}
                      px={[2, 4]}
                      spacing={1}
                      align="start"
                      w="100%"
                    >
                      <Flex justifyContent="space-between" width="100%">
                        <Tooltip
                          hasArrow
                          label={project?.fields?.title}
                          placement="top"
                        >
                          <HStack>
                            <Text
                              fontSize="sm"
                              noOfLines={1}
                              fontWeight="600"
                              align="left"
                              // onClick={e =>
                              //   handleLinkClick(
                              //     e,
                              //     project?.fields?.image?.fields?.file?.url
                              //   )
                              // }
                            >
                              {project?.fields?.title}
                            </Text>
                          </HStack>
                        </Tooltip>
                        <Flex alignItems="center">
                          <Icon as={AiOutlineStar} boxSize="0.9em" mt="1px" />
                          {/* <Box as="span" ml="1" fontSize="sm">
                      stargazers_count
                    </Box> */}
                        </Flex>
                      </Flex>
                      <Flex justifyContent="space-between" width="100%">
                        <Box>
                          <HStack spacing="1">
                            {project?.fields?.categories?.map((tech, j) => (
                              <Tag key={j} size="sm" colorScheme="cyan">
                                <Text
                                  fontSize={['0.55rem', 'inherit', 'inherit']}
                                >
                                  {tech?.fields?.name}
                                </Text>
                              </Tag>
                            ))}
                          </HStack>
                        </Box>
                      </Flex>
                    </VStack>
                  </VStack>
                </Box>
              ) : (
                <></>
              )}
            </>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default Portfolio;
