import React from 'react';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Center, Stack } from '@chakra-ui/layout';
import GetAQoute from '../Buttons/GetQoute';
import Whattsapp from '../Buttons/Whattsapp';
import { Image } from '@chakra-ui/image';

export default function Benifits({ title, description, imageUrl, imageTitle }) {
  //   console.log( imageTitle)
  return (
    <Box bg="white">
      {/* flex-col md:flex-row */}
      <div className="profile flex flex-col md:flex   lg:even:flex-row-reverse lg:odd:flex-row items-center  max-w-full mt-10 sm:mt-5 lg:mt-10 lg:max-w-full shadow-2xl p-4 bg-white">
        <Image boxSize={'48'} src={imageUrl} className=" " alt={imageTitle} />
        <div className="m-4">
          <p className="mt-2 text-3xl font-bold tracking-tight text-blue-400 sm:text-4xl">
            {title}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {documentToReactComponents(description)}
          </p>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}
              pt={'4'}
            >
              <Center>
                <GetAQoute text="GET A QUOTE" />
              </Center>
              <Whattsapp text="FREE CONSULTATION" />
            </Stack>
        </div>
      </div>
    </Box>
  );
}
