import * as contentful from 'contentful';

export const Client = contentful.createClient({
  space: `${process.env.REACT_APP_SPACE_ID}`,
  accessToken: `${process.env.REACT_APP_ACCESS_TOKEN}`,
});
export const fetchData = async value => {
  const entries = await Client.getEntries({
    content_type: value,
    // select: "fields",
  });
  // console.log(entries.items);
  return entries.items;
};
export const fetchOne = async (value, slug) => {
  const entry = await Client.getEntries({
    content_type: value,
    // limit: 1,
    // include: 10,
    'fields.titleSlug': `${slug}`,
  });
  // console.log(entry);
  return entry.items;
};

// https://www.contentful.com/blog/rendering-linked-assets-entries-in-contentful/
// const post = await client
// .getEntries({
//   content_type: "blogPost",
//   limit: 1,
//   include: 10,
//   "fields.slug": "the-power-of-the-contentful-rich-text-field",
// })
// .then((entry) => console.log(entry))
// .catch(console.error);

// const query = `{
//   blogPostCollection(limit: 1, where: {slug: "the-power-of-the-contentful-rich-text-field"}) {
//     items {
//       sys {
//         id
//       }
//       # For this example, we’ll focus on the Rich Text field query below
//       # and omit the rest of the blog post fields
//       body {
//         json
//         links {
//           entries {
//             inline {
//               sys {
//                 id
//               }
// const fetchOptions = {
//   method: "POST",
//   headers: {
//     Authorization: "Bearer " + {ACCESS_TOKEN},
//     "Content-Type": "application/json",
//   },
//   body: JSON.stringify({ query }),
// };

// const response = await fetch(`https://graphql.contentful.com/content/v1/spaces/{SPACE_ID}`, fetchOptions).then((response) => console.log(response.json()));
