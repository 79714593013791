import React from 'react';
import { NavLink } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Box,
  chakra,
  SimpleGrid,
  useColorModeValue,
  Button,
  Flex,
  HStack,
  Spacer,
  Image,
  Text,
} from '@chakra-ui/react';
import Section from './Section';
import useFetchServices from './getServices';
import logo from '../../assets/logo.png';
import Loading from '../../components/Loading';
import { usePWAInstall } from 'react-use-pwa-install';
import { useEffect } from 'react';

const Navbar = () => {
  const [serviceList] = useFetchServices('industries');
  const bg = useColorModeValue('white', 'gray.800');
  const cl = useColorModeValue('gray.800', 'white');
  const install = usePWAInstall();

  useEffect(() => {
    if (install) {
      install();
    }
  }, [install]);

  // console.log(serviceList);

  const Features = (
    <React.Fragment>
      <SimpleGrid
        columns={{
          base: 1,
          md: 3,
          lg: 5,
        }}
        pos="relative"
        gap={{
          base: 6,
          sm: 8,
        }}
        px={5}
        py={6}
        p={{
          sm: 8,
        }}
        bgColor={bg}
        zIndex={'docked'}
      >
        {serviceList.map((industry, i) => (
          <Box bg="blue.50" rounded={'3xl'}>
            <Section
              title={industry.fields.industaryName}
              id={i}
              icon={
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke-w="2"
                  d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                />
              }
            >
              <ol>
                {industry.fields.services.map((service, j) => (
                  <NavLink
                    id={j}
                    to={`/service/${service.fields.titleSlug}`}
                    state={{ some: service.fields }}
                  >
                    <li>{service.fields.menuTitle}</li>
                    <br />
                  </NavLink>
                ))}
              </ol>
            </Section>
          </Box>
        ))}
      </SimpleGrid>
    </React.Fragment>
  );
  if (!serviceList) return <Loading />;

  return (
    <React.Fragment>
      <chakra.header
        h="full"
        bg={bg}
        w="full"
        px={{
          base: 2,
          sm: 4,
        }}
        // py={2}
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <NavLink to={'/'}>
            <HStack display="flex" alignItems="center">
              <Image
                boxSize="80px"
                objectFit="cover"
                src={logo}
                alt="ULTRASTACK LOGO"
              />
              <Text fontWeight={'extrabold'} fontSize={'2xl'}>
                <p className="bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent hover:bg-gradient-to-l">
                  ULTRASTACK
                </p>
              </Text>
            </HStack>
          </NavLink>
          <Spacer />
          <Box display="flex" alignItems="center">
            <HStack spacing={1}>
              
              <Button
                colorScheme="blue"
                size="md"
                bgGradient="linear(to-l, #ae61fa, #fc77ba)"
                _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
              >
                <NavLink to="/contact">Contact Us</NavLink>
              </Button>
            </HStack>
            {/* <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              ml={{
                base: '0',
                md: '3',
              }}
              onClick={toggleMode}
              icon={<SwitchIcon />}
            /> */}
          </Box>
        </Flex>
        <Flex alignItems="center" justifyContent="center" mx="auto">
          <Box
            display={{
              md: 'inline-flex',
            }}
          >
            <HStack spacing={''}>
              <Box role="group">
                <Button
                  bg={bg}
                  color={!bg}
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  rightIcon={<IoIosArrowDown />}
                >
                  What We Do
                </Button>
                <Box
                  pos="absolute"
                  left={0}
                  w="full"
                  display="none"
                  bgGradient="linear(to-l, #ae61fa, #fc77ba)"
                  _hover={{ bgGradient: 'linear(to-l,  #fc77ba, #ae61fa)' }}
                  _groupHover={{
                    display: 'block',
                  }}
                >
                  {Features}
                </Box>
              </Box>
              <Box role="group">
                <Button
                  bg={bg}
                  color={!bg}
                  alignItems="center"
                  fontSize="md"
                  _hover={{
                    color: cl,
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  rightIcon={<IoIosArrowDown />}
                >
                  Who We Are
                </Button>
                <Box
                  pos="absolute"
                  left={0}
                  w="full"
                  display="none"
                  _groupHover={{
                    display: 'block',
                  }}
                >
                  <React.Fragment>
                    <SimpleGrid
                      columns={{
                        base: 1,
                        md: 3,
                        lg: 5,
                      }}
                      pos="relative"
                      gap={{
                        base: 6,
                        sm: 8,
                      }}
                      px={5}
                      py={6}
                      p={{
                        sm: 8,
                      }}
                      bgColor={bg}
                      zIndex={'docked'}
                    >
                      <Box bg="blue.50" rounded={'3xl'}>
                        <Section
                          title="Portfolio"
                          icon={
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-w="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          }
                        >
                          <NavLink to="/portfolio"> See Our Portfolio</NavLink>
                        </Section>
                      </Box>
                      <Box bg="blue.50" rounded={'3xl'}>
                        <Section
                          title="CaseStudies"
                          icon={
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-w="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          }
                        >
                          <NavLink to="/caseStudies">
                            See Our Case Studies
                          </NavLink>
                        </Section>
                      </Box>
                      <Box bg="blue.50" rounded={'3xl'}>
                        <Section
                          title="Clients Feedback"
                          icon={
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-w="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          }
                        >
                          <NavLink to="/feedback">
                            See Our clients Testimonials and reviews
                          </NavLink>
                        </Section>
                      </Box>
                      <Box bg="blue.50" rounded={'3xl'}>
                        <Section
                          title="Blog"
                          icon={
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-w="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          }
                        >
                          <NavLink to="/blog">See Our Blogs</NavLink>
                        </Section>
                      </Box>
                      <Box bg="blue.50" rounded={'3xl'}>
                        <Section
                          title="Terms & Condition"
                          icon={
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-w="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          }
                        >
                          <NavLink to="/termsandconditions">
                            See Our Terms & Condition
                          </NavLink>
                        </Section>
                      </Box>
                      <Box bg="blue.50" rounded={'3xl'}>
                        <Section
                          title="Privacy Policy"
                          icon={
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-w="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          }
                        >
                          <NavLink to="/privacyPolicy">
                            Privacy Policy description
                          </NavLink>
                        </Section>
                      </Box>
                      <Box bg="blue.50" rounded={'3xl'}>
                        <Section
                          title="Refund Policy"
                          icon={
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              stroke-w="2"
                              d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                            />
                          }
                        >
                          <NavLink to="/refund-policy">
                            See Our Refund Policy
                          </NavLink>
                        </Section>
                      </Box>
                      {/* 
                      <Box bg='blue.50' rounded={'3xl'}>
                        <Section
                        title="Our Story"
                        icon={
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke-w="2"
                            d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                          />
                        }
                      >
                        <NavLink  to="/story">our story description</NavLink>
                      </Section> 
                      </Box>
                      */}
                    </SimpleGrid>
                  </React.Fragment>
                </Box>
              </Box>
              {/* <Button
                bg={bg}
                color={!bg}
                display="inline-flex"
                alignItems="center"
                fontSize="md"
                _hover={{
                  color: cl,
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <NavLink to="/blog"> Blog</NavLink>
              </Button> */}
              <Button
                bg={bg}
                color={!bg}
                display="inline-flex"
                alignItems="center"
                fontSize="md"
                _hover={{
                  color: cl,
                }}
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <NavLink to="/news"> News</NavLink>
              </Button>
            </HStack>
          </Box>
        </Flex>
      </chakra.header>
      <hr />
    </React.Fragment>
  );
};

export default Navbar;
