import React from 'react';
import useFetchServices from '../../Navbar/getServices';
import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';
import { Avatar } from '@chakra-ui/avatar';
import Loading from '../../../components/Loading';

const Testimonial = () => {
  const [data] = useFetchServices('testimonials');
  // console.log(data);
if (!data) return <Loading />;
  return (
    <div>
      <Container maxW="7xl" py={10} px={6} bg="blue.100">
        <Flex justify="center" mb={8}>
          <chakra.h3 fontSize="3xl" fontWeight="bold" mb={3} textAlign="center">
            What people are saying about Us
          </chakra.h3>
        </Flex>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2 }}
          placeItems="center"
          spacing={1}
          mt={12}
          mb={4}
        >
          {data.map((obj, index) => (
            <Stack
              key={index}
              direction={{ base: 'column', sm: 'row' }}
              spacing={2}
              mb={5}
              justify="center"
            >
              <Stack
                maxW="345px"
                boxShadow="lg"
                rounded="md"
                p={6}
                pos="relative"
                bg="white"
                _after={{
                  content: `""`,
                  w: '0',
                  h: '0',
                  borderColor: `transparent white transparent`,
                  borderStyle: 'solid',
                  borderWidth: '10px 0 10px 10px',
                  position: 'absolute',
                  top: { base: 'unset', sm: '45%' },
                  right: { base: 'unset', sm: '-10px' },
                  left: { base: '48%', sm: 'unset' },
                  bottom: { base: '-15px', sm: 'unset' },
                  transform: { base: 'rotate(90deg)', sm: 'unset' },
                  display: 'block',
                }}
              >
                <Text fontWeight="medium" fontSize="sm">
                  {obj?.fields?.feedBack}
                </Text>
              </Stack>
              <Stack
                direction="column"
                spacing={2}
                p={2}
                justify="flex-end"
                alignItems="center"
              >
                <Avatar
                  size="lg"
                  showBorder={true}
                  borderColor="green.400"
                  name="avatar"
                  src={obj?.fields?.image?.fields?.file?.url}
                />
                <Box textAlign="center">
                  <Text fontWeight="bold" fontSize="md">
                    {obj?.fields?.name}
                  </Text>
                  <Text fontWeight="medium" fontSize="xs" color="gray.400">
                    {obj?.fields?.company}
                  </Text>
                </Box>
              </Stack>
            </Stack>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default Testimonial;
