import { Button } from '@chakra-ui/button'
import React from 'react'
import { NavLink } from 'react-router-dom'

const GetAQoute = ({text}) => {
    // console.log(text);
  return (
    <NavLink to={"/contact"}>

    <Button //bg-gradient-to-r from-amber-500 to-pink-500
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              colorScheme={'white'}
              bgGradient='linear(to-l, #ae61fa, #fc77ba)'
              _hover={{ bgGradient:'linear(to-l,  #fc77ba, #ae61fa)' }}>
              {text}
            </Button>
    </NavLink>
  )
}

export default GetAQoute