import {
  Flex,
  Link,
  Text,
  Container,
  VStack,
  Center,
  // IconButton,
} from '@chakra-ui/react';
import useFetchServices from '../Navbar/getServices';
// import Contact from '../WhoWeAre/Contact/ContactUs';
import { NavLink } from 'react-router-dom';
// import { FcGoogle } from 'react-icons/fc';
// import { FaFacebook } from 'react-icons/fa';
// import { SiLinkedin, SiMessenger } from 'react-icons/si';
import Socials from '../../components/Socials';
const Footer = () => {
  const [serviceList] = useFetchServices('industries');
  const footerData = [];

  serviceList.map(node => {
    // console.log(node.fields.industaryName);
    const s = node.fields.services;
    {
      // console.log('footer', n.fields.menuTitle);
      const obj = {
        label: node.fields.industaryName,
        href: node.fields.industaryName,
        links: [],
      };
      s.map(n => {
        const subObj = { label: n.fields.menuTitle, href: n.fields.titleSlug };
        return obj.links.push(subObj);
      });
      footerData.push(obj);
      // console.log(obj);
    }
    return node;
  });
  // console.log('FooterData', footerData);

    // const footerData1 = [
    //   {
    //     label: 'Writing',
    //     href: '#',
    //     links: [
    //       { label: 'Digital Garden', href: '#' },
    //       { label: 'Tutorials', href: '#' },
    //       { label: 'React', href: '#' },
    //       { label: 'Community', href: '#' },
    //     ],
    //   },
  // {
  //   label: 'Art',
  //   href: '#',
  //   links: [
  //     { label: 'Design', href: '#' },
  //     { label: '3D Art', href: '#' },
  //     { label: 'Photography', href: '#' }
  //   ]
  // },
  // {
  //   label: 'About',
  //   href: '#',
  //   links: [
  //     { label: 'Appearances', href: '#' },
  //     { label: 'Projects', href: '#' },
  //     { label: 'Uses', href: '#' }
  //   ]
  // },
  // {
  //   label: 'Social',
  //   href: '#',
  //   links: [
  //     { label: 'Email', href: '#' },
  //     { label: 'Twitter', href: '#' },
  //     { label: 'Github', href: '#' },
  //     { label: 'Linkedin', href: '#' },
  //     { label: 'RSS', href: '#' }
  //   ]
  // }
  //   ];
  return (
      <Container maxW="full" p={{ base: 5, md: 10 }} bgGradient='linear(to-l,  #fc77ba, #ae61fa)'>
        {/* <Contact /> */}
        <VStack spacing={5} alignItems="initial">
          <Flex
            flexWrap="wrap"
            direction={{ base: 'column', md: 'row' }}
            alignItems="start"
            justifyContent="space-between"
          ><Center>
          <Flex direction="column" mb="3" >
              <Text fontWeight="500" color="white">
                Socials
              </Text>
              
                
               
                <Socials/>
              {/* <Flex direction={{ base: 'row', md: 'column' }}></Flex> */}
            </Flex>
                </Center> 

            {footerData.map((data, index) => (
              
              <Flex key={index} direction="column" mb="3" justifyContent={"center"} alignItems={"center"}>
                <Text fontWeight="500" color="white">
                  {/* <Link fontWeight="500" href={data.href} color={cl}> */}
                  {data.label}
                </Text>
                <Flex direction={{ base: 'column', md: 'column' }}>
                  {data.links.map((link, index) => (
                    <Link
                      key={index}
                      padding={1}
                      fontSize={{ base: 'sm', sm: 'md' }}
                      
                      href={"/service/"+link.href}
                      mr={{ base: 1, sm: 2, md: 0 }}
                      color="white"
                      _hover={{ color: 'gray.900' }}
                    >
                      {link.label}
                    </Link>
                  ))}
                </Flex>
              </Flex>
            ))}
            <Flex direction="column" mb="3">
              <Text fontWeight="500" color="white">
                Who We Are
              </Text>
              <Flex direction={{ base: 'column', md: 'column' }}>
                {/* <NavLink to="/portfolio">
                  <Text
                    padding={1}
                    fontSize={{ base: 'sm', sm: 'md' }}
                    mr={{ base: 1, sm: 2, md: 0 }}
                    color="gray.500"
                    _hover={{ color: 'blue.600' }}
                  >
                    Portfolio
                  </Text>
                </NavLink> */}
                <NavLink to="/caseStudies">
                  <Text
                    padding={1}
                    fontSize={{ base: 'sm', sm: 'md' }}
                    mr={{ base: 1, sm: 2, md: 0 }}
                    color="white"
                    _hover={{ color: 'gray.900' }}
                  >
                    CaseStudies
                  </Text>
                </NavLink>
                <NavLink to="/feedback">
                  <Text
                    padding={1}
                    fontSize={{ base: 'sm', sm: 'md' }}
                    mr={{ base: 1, sm: 2, md: 0 }}
                    color="white"
                    _hover={{ color: 'gray.900' }}
                  >
                    Clients Feedback
                  </Text>
                </NavLink>
                {/* <NavLink to="/blog">
                  <Text
                    padding={1}
                    fontSize={{ base: 'sm', sm: 'md' }}
                    mr={{ base: 1, sm: 2, md: 0 }}
                    color="white"
                    _hover={{ color: 'gray.900' }}
                  >
                    Blog
                  </Text>
                </NavLink> */}
                <NavLink to="/termsandconditions">
                  <Text
                    padding={1}
                    fontSize={{ base: 'sm', sm: 'md' }}
                    mr={{ base: 1, sm: 2, md: 0 }}
                    color="white"
                    _hover={{ color: 'gray.900' }}
                  >
                    Terms & Condition
                  </Text>
                </NavLink>
                <NavLink to="/privacyPolicy">
                  <Text
                    padding={1}
                    fontSize={{ base: 'sm', sm: 'md' }}
                    mr={{ base: 1, sm: 2, md: 0 }}
                    color="white"
                    _hover={{ color: 'gray.900' }}
                  >
                    Privacy Policy
                  </Text>
                </NavLink>
                <NavLink to="/refund-policy">
                  <Text
                    padding={1}
                    fontSize={{ base: 'sm', sm: 'md' }}
                    mr={{ base: 1, sm: 2, md: 0 }}
                    color="white"
                    _hover={{ color: 'gray.900' }}
                  >
                    Refund Policy
                  </Text>
                </NavLink>
              </Flex>
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <Text color="white" fontSize="0.875rem" pl="0.5rem">
              &copy; 2019 company, Inc. All rights reserved.
            </Text>
          </Flex>
        </VStack>
      </Container>
  );
};

export default Footer;
