import React from 'react';
import { Helmet } from 'react-helmet';

const SeoAndOgTag = props => {
  const { metaTitle, metaDescription, metaImage, url, keywords } = props;
  const URL = `https://ultrastack.org/${url}`;
  // console.log(keywords);
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content={ keywords ? keywords : 'ultrastack, amazon, ecommerce, ppc'}
        />
        {/* <!-- Open Graph / Facebook --/> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={URL} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImage} />

        {/* <!-- Twitter --/> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={URL} />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={metaImage} />
      </Helmet>
    </>
  );
};

export default SeoAndOgTag;
// {
//     "hostname": "https://celikk.me",
//     "twitterUsername": "@celikkoseoglu"
//   }
