import { useState, useEffect } from "react";
import { fetchData } from "../../clinet";

const useFetchServices = (props) => {
  
    const [serviceList, setServiceList] = useState([]);
// console.log(props);
    useEffect(() => {
        fetchData(props)
          .then(res => {
            // console.log(res);
            setServiceList(res);
          })
          .catch(err => {
            console.log(err);
          });
      }, [props]);

  return [serviceList];
};

export default useFetchServices;