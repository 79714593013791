import { Button } from '@chakra-ui/button';
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const Whattsapp = ({ text }) => {
  // console.log(text);
  return (
    <a href={'https://wa.me/+923138876876'} target="_blank" rel="noreferrer">
      <Button
        leftIcon={<FaWhatsapp size={'26px'} />}
        rounded={'full'}
        size={'lg'}
        fontWeight={'normal'}
        px={6}
        colorScheme={'white'}
        bg="#25D366"
      >
        {text}
      </Button>
    </a>
  );
};

export default Whattsapp;
