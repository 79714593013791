import React from "react";

import {   Card, CardBody,  Divider,  Heading,    Stack, Text,} from "@chakra-ui/react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Image } from "@chakra-ui/image";
import { Center } from "@chakra-ui/layout";
import GetAQoute from "../Buttons/GetQoute";
import Whattsapp from "../Buttons/Whattsapp";

export default function Feature({title, description, imageUrl, imageTitle}){
  // console.log(title, description, imageUrl, imageTitle);
  return (
<Card maxW='md'>
  <CardBody>
    <Center>

    <Image
    boxSize={'48'}
      src={imageUrl}
      alt={imageTitle}
      borderRadius='lg'
      
    />
    </Center>
    <Stack mt='6' spacing='3'>
      <Heading size='md'>{title}</Heading>
      <Text>
        {documentToReactComponents(description)}
      </Text>
      {/* <Text color='blue.600' fontSize='2xl'>
        $450
      </Text> */}
    </Stack>
    <Stack
              spacing={{ base: 4, sm: 6 }}
              direction='column'
              pt={'4'}
            >
              <Center>
                <GetAQoute text="GET A QUOTE" />
              </Center>
              <Center>

              <Whattsapp text="FREE CONSULTATION" />
              </Center>
            </Stack>
  </CardBody>
  <Divider />
  {/* <CardFooter>
    <ButtonGroup spacing='2'>
      <Button variant='solid' colorScheme='blue'>
        Buy now
      </Button>
      <Button variant='ghost' colorScheme='blue'>
        Add to cart
      </Button>
    </ButtonGroup>
  </CardFooter> */}
</Card>
  );
};
