import React from 'react';


import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Center } from '@chakra-ui/react';
import { Stack } from '@chakra-ui/layout';
import GetAQoute from '../Buttons/GetQoute';
import Whattsapp from '../Buttons/Whattsapp';

export default function Feature2({ title, description, imageUrl, imageTitle }) {
  // console.log(title, description, imageUrl, imageTitle);
  return (
    <div >
      <Center>
        <img
          className="object-contain w-full h-56 rounded-lg lg:w-64"
          src={imageUrl}
          alt={imageTitle}
        />
      </Center>
      <div className="flex flex-col justify-between py-1 lg:mx-6">
        <p className="text-xl font-semibold text-blue-700  ">
          {title}
          <br />
          <span className="text-sm text-gray-500 ">
            {documentToReactComponents(description)}
          </span>
        </p>
        <Stack
              spacing={{ base: 4, sm: 6 }}
              direction='column'
              pt={'4'}
            >
              <Center>
                <GetAQoute text="GET A QUOTE" />
              </Center>
              <Center>

              <Whattsapp text="FREE CONSULTATION" />
              </Center>
            </Stack>
      </div>
    </div>
  );
}
