import { IconButton } from '@chakra-ui/button';
import { EmailIcon } from '@chakra-ui/icons';
import { Box, Flex } from '@chakra-ui/layout';
import React from 'react';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
const accounts = [
  {
    url: 'https://www.facebook.com/ultrastack',
    label: 'Facebook Account',
    type: 'blue',
    icon: <FaFacebookF />,
  },
  {
    url: 'https://twitter.com/UltraStack',
    label: 'Twitter Account',
    type: 'twitter',
    icon: <FaTwitter />,
  },
  {
    url: 'https://www.instagram.com/ultrastack/',
    label: 'Instagram Account',
    type: 'pink',
    icon: <FaInstagram />,
  },
  {
    url: 'https://www.linkedin.com/company/ultrastack-pk',
    label: 'LinkedIn Account',
    type: 'linkedin',
    icon: <FaLinkedin />,
  },
  {
    url: 'https://wa.me/+923138876876',
    label: 'Whattsapp Account',
    type: 'green',
    icon: <FaWhatsapp />,
  },
  {
    url: 'mailto:email4ultrastack@gmail.com',
    label: 'Gmail Account',
    type: 'gray',
    icon: <EmailIcon />,
  },
];
const Socials2 = () => {
  return (
    <div>
      {' '}
      <Flex alignItems="center" justify="center" w="100%">
        <Box textAlign="center" >
          {accounts.map((sc, index) => (
            <NavLink to={sc.url} target='_blank'>

                <IconButton
                  key={index}
                  isExternal
                  ml={6}
                  boxSize={"12"}
                  
                  aria-label={sc.label}
                  colorScheme={sc.type}
                  rounded="full"
                  icon={sc.icon}
                //   {...iconProps}
                />
            </NavLink>
          ))}
        </Box>
      </Flex>
    </div>
  );
};

export default Socials2;
