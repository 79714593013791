import React from 'react'
import useFetchServices from '../GetData'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Box, Text } from '@chakra-ui/react';
import Loading from '../../../components/Loading';


const PrivacyPolicy = () => {
  const [data] = useFetchServices("privacyPolicy")
  // console.log(data);
  
if (!data) return <Loading />;
  return (
    <Box p={['10', '20']}>
    <Text>{documentToReactComponents(data?.policy)}</Text>
    </Box>
  )
}

export default PrivacyPolicy