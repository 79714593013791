import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { fetchOne } from '../../../clinet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Hero2 from '../../../components/Hero/Hero2';
import { Box, Center } from '@chakra-ui/react';
import Section from '../../../components/Section';
import Feature2 from '../../../components/Features/Features2';
import Loading from '../../../components/Loading';
import Faq from '../../../components/Faq';
import {
  AspectRatio,
  Container,
  Flex,
  HStack,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
// import { useDisclosure } from '@chakra-ui/hooks';
import { Icon } from '@chakra-ui/icon';
import { AiOutlineStar } from 'react-icons/ai';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import moment from 'moment';
import { Image } from '@chakra-ui/image';
import SeoAndOgTag from '../../../components/SeoAndOgTag';
import Socials2 from '../../../components/Socials2';
import { Suspense } from 'react';
const LazyImage = props => {
  const { src, title } = props;
  return <Image src={src} objectFit="cover" alt={title} />;
};
const Service2 = () => {
  const { slug } = useParams();
  const [singleService, setSingleService] = useState({});
  // const { isOpen, onOpen, onClose } = useDisclosure();

  // const handleClick = () => {
  //   onOpen();
  // };

  // const handleLinkClick = (e, link) => {
  //   window.open(link);
  //   e.stopPropagation();
  // };

  // console.log(singleService);
  useEffect(() => {
    fetchOne('Services', slug)
      .then(res => 
        // console.log(res[0].fields);
        setSingleService(res[0])
      )
      .catch(err => {
        console.log(err);
      });
  }, [slug]);
  console.log(singleService);
  if (!singleService) return <Loading />;
  return (
    <Suspense fallback={<Loading/>}>

    <Box bg="blue.50">
      <SeoAndOgTag
        metaTitle={singleService?.fields?.title2}
        metaDescription={singleService?.fields?.ogDescription}
        metaImage={singleService?.fields?.paragraphImage?.fields?.file?.url}
        url={`/service/2/${slug}`}
        keywords={
          singleService?.fields?.keywords
            ? `${singleService?.fields?.keywords.map(kw => kw)}`
            : ''
        }
      />
      <Hero2
        title={singleService?.fields?.title2}
        description={singleService?.fields?.description2}
      />
      <Socials2 />
      <br />
      <Section
        title={singleService?.fields?.paragraphTitle}
        description={documentToReactComponents(
          singleService?.fields?.paragraphDescription
        )}
        imageUrl={singleService?.fields?.paragraphImage?.fields?.file?.url}
      />
      <br />
      <Socials2 />
      <br />
      <section className="bg-blue.100">
        <div className="container px-6 py-10 mx-auto">
          <Center>
            <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
              HOW IT WORKS
            </p>
          </Center>
          <div className="grid grid-cols-1 gap-8 mt-8 md:mt-16 md:grid-cols-2">
            {singleService?.fields?.benifits2?.map((benifit, i) => (
              <span key={i}>
                <Feature2
                  title={benifit?.fields?.title}
                  description={benifit?.fields?.description}
                  imageUrl={benifit?.fields?.image?.fields?.file?.url}
                  imageTitle={benifit?.fields?.image?.fields?.title}
                />
              </span>
            ))}
          </div>
        </div>
        <Socials2 />
        <br />
        <Center>
          <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
            FAQ's
          </p>
          <br />
          <br />
        </Center>
        {singleService?.fields?.faqs?.map((faq, i) => (
          <Center>
            <Faq key={i} q={faq?.fields?.question} a={faq?.fields?.answer} />
          </Center>
        ))}
      </section>
      <div className="bg-blue-50">
        {' '}
        <br />
        <Socials2 />
        <br />
        <Container maxW="7xl" p="5" mx="auto">
          <Center>
            <Heading>
              {' '}
              <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
                Portfolio
              </p>
            </Heading>
          </Center>
          <SimpleGrid columns={[1, 2]} spacing={4} mt={8}>
            {singleService?.fields?.portfolio?.map((project, i) => (
              <>
                {project?.fields?.isVideo ? (
                  <Box
                  //  onClick={handleClick} 
                  cursor="pointer" size="xl">
                    <VStack
                      rounded="xl"
                      borderWidth="1px"
                      bg={'gray.100'}
                      borderColor={'gray.700'}
                      _hover={{
                        shadow: 'lg',
                        textDecoration: 'none',
                      }}
                      overflow="hidden"
                      align="start"
                      spacing={0}
                    >
                      <Box position="relative" w="100%">
                        <AspectRatio
                          ratio={1.85 / 1}
                          w="100%"
                          borderBottomWidth="1px"
                          borderColor={'gray.100'}
                        >
                          <iframe
                            src={project?.fields?.image?.fields?.file?.url}
                            title={project?.fields?.image?.fields?.title}
                            allowFullScreen
                            allowPinchZoom
                          />
                        </AspectRatio>
                      </Box>

                      <VStack
                        py={2}
                        px={[2, 4]}
                        spacing={1}
                        align="start"
                        w="100%"
                      >
                        <Flex justifyContent="space-between" width="100%">
                          <Tooltip
                            hasArrow
                            label={project?.fields?.title}
                            placement="top"
                          >
                            <HStack>
                              <Text
                                fontSize="sm"
                                noOfLines={1}
                                fontWeight="600"
                                align="left"
                                // onClick={e =>
                                //   handleLinkClick(
                                //     e,
                                //     project?.fields?.image?.fields?.file?.url
                                //   )
                                // }
                              >
                                {project?.fields?.title}
                              </Text>
                            </HStack>
                          </Tooltip>
                          <Flex alignItems="center">
                            <Icon as={AiOutlineStar} boxSize="0.9em" mt="1px" />
                            {/* <Box as="span" ml="1" fontSize="sm">
                    stargazers_count
                  </Box> */}
                          </Flex>
                        </Flex>
                      </VStack>
                    </VStack>
                  </Box>
                ) : (
                  <Box 
                  // onClick={handleClick} 
                  cursor="pointer" size="xl">
                    <VStack
                      rounded="xl"
                      borderWidth="1px"
                      bg={'gray.100'}
                      borderColor={'gray.700'}
                      _hover={{
                        shadow: 'lg',
                        textDecoration: 'none',
                      }}
                      overflow="hidden"
                      align="start"
                      spacing={0}
                    >
                      <Box position="relative" w="100%">
                        <AspectRatio
                          ratio={1.85 / 1}
                          w="100%"
                          borderBottomWidth="1px"
                          borderColor={'gray.100'}
                        >
                          <LazyImage
                            src={project?.fields?.image?.fields?.file?.url}
                            title={project?.fields?.image?.fields?.title}
                          />
                        </AspectRatio>
                      </Box>

                      <VStack
                        py={2}
                        px={[2, 4]}
                        spacing={1}
                        align="start"
                        w="100%"
                      >
                        <Flex justifyContent="space-between" width="100%">
                          <Tooltip
                            hasArrow
                            label={project?.fields?.title}
                            placement="top"
                          >
                            <HStack>
                              <Text
                                fontSize="sm"
                                noOfLines={1}
                                fontWeight="600"
                                align="left"
                                // onClick={e =>
                                //   handleLinkClick(
                                //     e,
                                //     project?.fields?.image?.fields?.file?.url
                                //   )
                                // }
                              >
                                {project?.fields?.title}
                              </Text>
                            </HStack>
                          </Tooltip>
                          <Flex alignItems="center">
                            <Icon as={AiOutlineStar} boxSize="0.9em" mt="1px" />
                            {/* <Box as="span" ml="1" fontSize="sm">
                        stargazers_count
                      </Box> */}
                          </Flex>
                        </Flex>
                      </VStack>
                    </VStack>
                    <Modal
                      // isOpen={isOpen}
                      // onClose={onClose}
                      isCentered
                      allowPinchZoom
                    >
                      <ModalOverlay />
                      <ModalContent bg="none" maxW="28rem" w="auto">
                        <ModalBody
                          p={0}
                          rounded="lg"
                          overflow="hidden"
                          bg="none"
                        >
                          <Center>
                            <Image
                              src={project?.fields?.image?.fields?.file?.url}
                              rounded="lg"
                              alt="Repo image"
                            />
                          </Center>
                        </ModalBody>
                      </ModalContent>
                    </Modal>
                  </Box>
                )}
              </>
            ))}
          </SimpleGrid>
        </Container>
      </div>
      <Socials2 />
      <section className="bg-blue-50 ">
        <div className="container mx-auto px-6 py-10">
          <div className="text-center">
            <h1 className="text-3xl font-semibold capitalize text-gray-800  lg:text-4xl">
              <p className="mt-2 text-3xl font-bold tracking-tight bg-gradient-to-r from-pink-500 to-purple-500 bg-clip-text text-transparent sm:text-4xl">
                Blog
              </p>
            </h1>

            {/* <p className="mx-auto mt-4 max-w-lg text-gray-500">Salami mustard spice tea fridge authentic Chinese food dish salt tasty liquor. Sweet savory foodtruck pie.</p> */}
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 xl:grid-cols-3">
            {singleService?.fields?.blogs?.map((blog, i) => (
              <div key={i} className="shadow-2xl rounded-2xl bg-white">
                <NavLink key={i} to={`/blog/${blog?.fields?.titleSlug}`}>
                  <div className="relative ">
                    <img
                      className="h-64 w-full rounded-lg object-contain object-center lg:h-80"
                      src={blog?.fields?.heroImage?.fields?.file?.url}
                      alt=""
                    />

                    <div className="absolute bottom-0 flex bg-blue-100 p-3 ">
                      <img
                        className="h-10 w-10 rounded-full object-cover object-center"
                        src={blog?.fields?.autherImage?.fields?.file?.url}
                        alt={blog?.fields?.auther}
                      />

                      <div className="mx-4">
                        <h1 className="text-sm text-gray-700 ">
                          {blog?.fields?.auther}
                        </h1>
                        {/* <p className="text-sm text-gray-500 ">Creative Director</p> */}
                      </div>
                    </div>
                  </div>

                  <Center>
                    <h1 className="mt-6 text-xl font-semibold text-gray-800 ">
                      {blog?.fields?.title}
                    </h1>
                  </Center>
                  <Center>
                    <p>
                      {moment(blog?.fields?.date).utc().format('DD-MM-YYYY')}
                    </p>
                  </Center>
                  <Center>
                    <p className="mt-4 inline-block text-blue-500 underline hover:text-blue-400 ">
                      Read more
                    </p>
                  </Center>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Box>
    </Suspense>
  );
};

export default Service2;
